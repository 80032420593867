<!--
 * @Author: DZM
 * @Date: 2022-08-16 09:54:40
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-17 14:40:05
 * @Description: 
-->
<template>
    <div class="detailedBox" v-show="personnelShow">
      <img class="close_url" src="./img/close.png" alt="" @click="close">
      <div class="info">
        <div class="dataShow">
          <div class="data_nameType">员工姓名:</div>
          <div class="data_content">{{personnelInfo.pdaManagerName}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">角色：</div>
          <div class="data_content">{{personnelInfo.pdaManagerRoleName}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">员工编号：</div>
          <div class="data_content">{{personnelInfo.pdaManagerCode}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">手机号：</div>
          <div class="data_content">{{personnelInfo.pdaManagerMobile}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">账号：</div>
          <div class="data_content">{{personnelInfo.account}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">所属商户：</div>
          <div class="data_content">{{personnelInfo.operationName}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">所属部门：</div>
          <div class="data_content">{{personnelInfo.deptName}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">管辖道路：</div>
          <el-tooltip class="item" effect="dark" :content="personnelInfo.parkName" placement="right-end">
             <div class="data_content">{{personnelInfo.parkName | wordProcessing(20)}}</div>
         </el-tooltip>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "personnel",
  data() {
    return {
      imgColor: null,
      personnelShow: false,
      personnelInfo: "",
    }
  },
  methods: {
    close() {
      this.personnelShow = false;
    },
    open(data) {
      this.personnelShow = true;
      console.log("测试this.personnelShow", this.personnelShow);
      this.getPersonnelInfo(data);
    },
    img_url(val) {
      this.imgColor = val;
    },
    getPersonnelInfo(berthId) {
        this.$axios
          .get("/acb/2.0/bigScreen/map/pdaManagerInfo", {
            data: {
              pdaManagerId: berthId,
            }
          })
          .then(res => {
            console.log("泊位idres", res);
            this.personnelInfo = res.value
          });
    },
  },
}
</script>
<style lang="stylus" scoped>
.detailedBox {
  padding: 19px 0 20px  15px;
  width: 341px;
  position: absolute;
  top: 80px;
  left: 25%;
  background: linear-gradient(173deg, rgba(170,205,255,0.1600) 0%, rgba(22,76,142,0.2800) 100%);
  box-shadow: inset 2px 2px 2px 0px rgba(149,223,255,0.3);
  border-radius: 4px;
  border: 0px solid;
  border-image: linear-gradient(172deg, rgba(239, 250, 255, 0.5), rgba(55, 211, 255, 0.7)) 0 0;
  backdrop-filter: blur(6px);
  z-index: 99
  .close_url {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .info {
    color: #D8D8D8;
    .dataShow {
      display: flex;
      justify-content:flex-start;
      line-height: 25px;
      .imglist {
        width: 100%;
        display: flex;
        justify-content:space-evenly;
        margin-top: 15px;
        .img_url {
          width: 100px;
          height: 75px;
          border: 1px solid red;
          border-radius: 2px;
        }
        .img_urlColor {
          width: 100px;
          height: 75px;
          box-shadow: inset 0px 1px 10px 4px rgba(93,176,230,0.36);
          border-radius: 2px;
          border: 1px solid #5DB0E6;
        }
      }
      .data_nameType {
        width: 73px;
        height: 15px;
        font-size: 11px;
      }
      .data_content {
        font-size: 11px;
        color: #c8edff;
      }
    }
  }
}
</style>
