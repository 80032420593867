<template>
  <div class="monitorDetails">
    <el-dialog
      :visible.sync="dialogVisible"
      class="mask videoDetaiDialog"
      :center="true"
      top="10vh"
      :title="monitorParkName"
      :before-close="handleClose"
      :show-close="false"
      :modal="false"
    >
      <div class="imgClose" @click="dialogVisible = false"></div>
      <GeminiScrollbar class="parkRecordList" v-if="monitorList.length > 0">
        <!-- <div slot="title" class="el-dialog__title">
          {{ monitorParkName }}
        </div> -->
        <!-- <GeminiScrollbar class="dialogVideo"> -->
        <div class="dialogVideo">
          <div class="monitorBox">
            <div
              class="monitor"
              v-for="(item, idx) in monitorList"
              :key="idx"
              @click="showVideo(item)"
            >
              <!-- status 0离线 1在线 -->
              <!-- <div class="img" :class="item.monitorEquipmentState ? 'onimg' : 'offimg'"></div> -->
              <div class="img onimg"></div>
              <div class="monitorName">{{ item.monitorEquipmentName | textLength }}</div>
            </div>
          </div>
        </div>
        <!-- </GeminiScrollbar> -->
        <!-- <span slot="footer" class="dialog-footer"> -->
        <!-- <el-button @click="fastView">快速预览</el-button> -->
        <!-- <el-button @click="dialogVisible = false">关 闭</el-button> -->
        <!-- </span> -->
      </GeminiScrollbar>
    </el-dialog>
    <video-monitor
      ref="videoMonitor"
      :passageway-list="passagewayList"
      :isShowChange="isShowChange"
      @change="videoChange"
      v-if="isShowVideoDialog"
      :title="title"
      :isPlayBack="isPlayBack"
      @close="isShowVideoDialog = false"
    ></video-monitor>
  </div>
</template>

<script>
import VideoMonitor from "@/components/video-monitor/newindex";
export default {
  props: ["monitorList", "monitorParkName"],
  components: {
    VideoMonitor,
  },
  filters: {
    textLength(val) {
      if (val == undefined) {
      } else {
        if (val.length > 8) {
          return val.slice(0, 8) + "...";
        }
        return val;
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      monitorDeviceNo: "",
      monitorDeviceName: "",
      title: "",
      passagewayList: [],
      isShowChange: true,
      isShowVideoDialog: false,
      passagewayPageSize: 5,
      passagewayPage: 1,
      passagewayTotal: 0,
      selectData: {},
      isPlayBack: false, // 是否支持回放
    };
  },
  computed: {
    getNewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector("body");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  },
  methods: {
    handleClose(done) {
      done();
    },
    async showVideo(item) {
      // if(item.monitorEquipmentState == '0'){
      //   return
      // }

      this.passagewayPageSize = 5;
      this.passagewayPage = 1;
      this.passagewayTotal = 0;
      await this.getPassagewayList(item.monitorEquipmentId);
      if (this.passagewayList.length == 0) {
        this.$alert("此监控下没有监控设备！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.selectData = item;
      this.title = item.monitorEquipmentName;
      this.dialogVisible = false;
      this.isShowVideoDialog = true;
      this.$nextTick(() => {
        this.$refs.videoMonitor.open();
      });
    },
    async videoChange() {
      if (this.passagewayPage > this.passagewayFlag) {
        this.passagewayPage = 1;
      }
      await this.getPassagewayList(this.selectData.monitorEquipmentId);
      this.$nextTick(() => {
        this.$refs.videoMonitor.clearData();
      });
    },
    getPassagewayList(monitorEquipmentId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/nvrMonitorRoute/listMonitorEquipmentChannel", {
            data: {
              pageNum: this.passagewayPage,
              pageSize: this.passagewayPageSize,
              monitorEquipmentId,
              parkId: this.getNewRegionalCollection.parkId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.passagewayList = res.value.list;
              this.passagewayTotal = res.value.total;
              this.passagewayFlagA = this.passagewayTotal % this.passagewayPageSize;
              this.passagewayFlagB = parseInt(this.passagewayTotal / this.passagewayPageSize);
              if (this.passagewayFlagA > 0) {
                this.passagewayFlag = this.passagewayFlagB + 1;
              } else {
                this.passagewayFlag = this.passagewayFlagB;
              }
              // console.log(this.passagewayFlag);
              this.passagewayPage++;
              // this.passagewayFlag = this.passagewayTotal/this.passagewayPageSize
            } else {
              this.passagewayPage = 1;
              this.passagewayList = [];
              this.passagewayTotal = 0;
            }
            if (this.passagewayTotal > 5) {
              this.isShowChange = true;
            } else {
              this.isShowChange = false;
            }
            resolve();
          });
      });
    },
  },
  watch: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.videoDetaiDialog >>>.el-dialog {
  width: 664px;
  height: 358px;
  background: url('../img/bj.png') no-repeat center / 100% 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  // margin: 54px auto !important;
}
.videoDetaiDialog >>>.el-dialog__header {
	padding: 18px 0px 0px 0px;
}
 .videoDetaiDialog >>>.el-dialog__title {
	font-size: 16px;
	color: #D6F3FF;
}
.videoDetaiDialog .el-dialog--center {
	width: 664px;
}
 .videoDetaiDialog >>> .el-dialog__body {
	width: 568px;
    height: 270px;
	padding: 0px 50px;
  }
.videoDetaiDialog {
	display: flex;
	align-items: center;
  >>>.el-dialog--center {
    .el-dialog__body {
      .dialogVideo {
       margin-top: 8px;
      }
	  .title {
		text-align: center;
		color: #D6F3FF;
		font-size: 30px;
		margin-top: 12px;
		margin-bottom: 45px;
	  }
      .monitorBox {
        .monitor {
          float: left;
		  width: 126px;
		  height: 74px;
		  background: rgba(47,93,157,0.47);
		  margin:8px;
		  border-radius: 2px;
		  text-align: center;
		//   display: flex;
		//   align-items: center;
		  cursor: pointer;
          .img {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-top: 12px;
          }

          .onimg {
            background: url('../img/cream.png') no-repeat center / 100% 100%;
          }

          .offimg {
            background: url('../img/offline.png') no-repeat center / 100% 100%;
          }

          .monitorName {
			margin-top: 10px;
            font-size: 12px;
            color: #CEE7F5;
			text-align: center
          }
        }
      }
    }
	.imgClose {
		 background: url('../img/close.png') no-repeat center / 100% 100%;
		 width: 20px;
		 height: 20px;
		 position: absolute;
		 right: 8px;
		 top: 0px;
	}
    // .el-dialog__footer {
    //   background-color: #07226f;
    //   padding-bottom: 10px;

    //   .dialog-footer {
    //     .el-button {
    //       width: 88px;
    //       height: 30px;
    //       padding: 0;
    //       color: #fff;
    //       background-color: #0076F5;
    //       border: none;
    //       margin-right: 10px;
    //     }
    //   }
    // }
  }
}
>>>.gm-scroll-view {
	width: 585px !important;
	height: 287px !important;
	}
</style>
