<!--
 * @Author: DZM
 * @Date: 2022-08-16 09:54:40
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-17 14:40:05
 * @Description: 
-->
<template>
    <div class="detailedBox" v-show="dialogShow">
      <img class="close_url" src="./img/close.png" alt="" @click="close">
      <div class="info">
        <div class="dataShow">
          <div class="data_nameType">商户：</div>
          <div class="data_content">{{berthInfo.operationName}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">车场：</div>
          <div class="data_content">{{berthInfo.parkName}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">泊位号：</div>
          <div class="data_content">{{berthInfo.berthCode}}</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">设备类型：</div>
          <div class="data_content">高位视频</div>
        </div>
        <div class="dataShow">
          <div class="data_nameType">泊位状态：</div>
          <div class="data_content">{{berthInfo.berthState == 0 ? "空闲":"占用"}}</div>
        </div>
        <div class="dataShow" v-show="berthInfo.plateNumber">
          <div class="data_nameType">车牌号：</div>
          <div class="data_content">{{berthInfo.plateNumber}}</div>
        </div>
        <div class="dataShow" v-show="berthInfo.entryTime">
          <div class="data_nameType">入场时间：</div>
          <div class="data_content">{{berthInfo.entryTime}}</div>
        </div>
        <div class="dataShow" v-show="berthInfo.parkTime">
          <div class="data_nameType">停车时长：</div>
          <div class="data_content">{{berthInfo.parkTime}}</div>
        </div>
        <div class="dataShow" v-show="berthInfo.exitTime">
          <div class="data_nameType">出场时间：</div>
          <div class="data_content">{{berthInfo.exitTime}}</div>
        </div>
        <div class="dataShow" v-show="berthInfo.entryOprNum">
          <div class="data_nameType">车辆照片</div>
          <div class="data_content"></div>
        </div>
        <div class="dataShow" v-show="berthInfo.entryOprNum">
          <div class="imglist">
            <el-image   v-for="item in entryPic" :key="item" :src="item" alt="" class="img_urlColor" :preview-src-list="entryPic" @click="img_url(0)" ></el-image>
             <!-- <div :class="imgColor == 1 ? 'img_urlColor' : 'img_url'" @click="img_url(1)">

            </div> -->
            <!--<div :class="imgColor == 2 ? 'img_urlColor' : 'img_url'" @click="img_url(2)"></div> -->
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "detailedBox",
  data() {
    return {
      imgColor: null,
      dialogShow: false,
      berthInfo: "",
      entryPic: [],
    }
  },
  methods: {
    close() {
      this.dialogShow = false;
    },
    open(data) {
      this.dialogShow = true;
      console.log("测试this.dialogShow", this.dialogShow);
      this.getberthInfo(data);
    },
    img_url(val) {
      this.imgColor = val;
    },
    getberthInfo(berthId) {
        this.$axios
          .get("/acb/2.0/bigScreen/map/berthInfo", {
            data: {
              berthId: berthId,
            }
          })
          .then(res => {
            console.log("泊位idres", res);
            this.berthInfo = res.value
            this.getPic(res.value.entryOprNum);
          });
    },
     // 获取停车场详情图片
    getPic(entryOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              // this.entryPic = res.value;
              
              for (let item of res.value) {
                  this.entryPic.push(item.picUrl)
              }
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.detailedBox {
  padding: 19px 0 20px  15px;
  width: 341px;
  position: absolute;
  top: 80px;
  left: 25%
  background: linear-gradient(173deg, rgba(170,205,255,0.1600) 0%, rgba(22,76,142,0.2800) 100%);
  box-shadow: inset 2px 2px 2px 0px rgba(149,223,255,0.3);
  border-radius: 4px;
  border: 0px solid;
  border-image: linear-gradient(172deg, rgba(239, 250, 255, 0.5), rgba(55, 211, 255, 0.7)) 0 0;
  backdrop-filter: blur(6px);
  z-index: 99
  .close_url {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .info {
    color: #D8D8D8;
    .dataShow {
      display: flex;
      justify-content:flex-start;
      line-height: 25px;
      .imglist {
        width: 100%;
        display: flex;
        justify-content:space-evenly;
        margin-top: 15px;
        .img_url {
          width: 100px;
          height: 75px;
          border: 1px solid red;
          border-radius: 2px;
        }
        .img_urlColor {
          width: 100px;
          height: 75px;
          box-shadow: inset 0px 1px 10px 4px rgba(93,176,230,0.36);
          border-radius: 2px;
          border: 1px solid #5DB0E6;
        }
      }
      .data_nameType {
        width: 73px;
        height: 15px;
        font-size: 11px;
      }
      .data_content {
        font-size: 11px;
        color: #c8edff;
      }
    }
  }
}
</style>
