<template>
  <div class="monitorDetails">
    <el-dialog
      :visible.sync="dialogVisible"
      :center="true"
      width="635px"
      top="10vh"
      :before-close="handleClose"
      :show-close="false"
      :modal="false"
    >
      <div slot="title" class="el-dialog__title">
        {{ monitorParkName }}
      </div>
      <!-- <GeminiScrollbar class="dialogVideo"> -->
      <div class="dialogVideo">
        <div class="monitorBox">
          <div
            class="monitor"
            v-for="(item, idx) in monitorList"
            :key="idx"
            @click="showVideo(item)"
          >
            <!-- status 0离线 1在线 -->
            <!-- <div class="img" :class="item.monitorEquipmentState ? 'onimg' : 'offimg'"></div> -->
             <div class="img onimg" ></div> 
            <div class="monitorName">{{ item.monitorEquipmentName }}</div>
          </div>
        </div>
      </div>  
      <!-- </GeminiScrollbar> -->
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="fastView">快速预览</el-button> -->
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <video-monitor
      ref="videoMonitor"
      :passageway-list="passagewayList"
      :isShowChange="isShowChange"
      @change="videoChange"
      v-if="isShowVideoDialog"
      :title="title"
      :isPlayBack="isPlayBack"
      @close="isShowVideoDialog = false"
    ></video-monitor>
  </div>
</template>

<script>
import VideoMonitor from "@/components/video-monitor/"; 
export default {
  props: ["monitorList", "monitorParkName"],
  components: {
    VideoMonitor
  },
  data() {
    return {
      dialogVisible: false,
      monitorDeviceNo: "",
      monitorDeviceName: "",
      title: '',
       passagewayList: [],
      isShowChange: true,
      isShowVideoDialog: false,
      passagewayPageSize: 5,
      passagewayPage: 1,
      passagewayTotal: 0,
      selectData: {},
      isPlayBack: false, // 是否支持回放
    };
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector("body");
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  },
  methods: {
    handleClose(done) {
      done();
    },
    async showVideo(item) {
      // if(item.monitorEquipmentState == '0'){
      //   return
      // }
      
      this.passagewayPageSize = 5;
        this.passagewayPage = 1;
        this.passagewayTotal = 0;
        await this.getPassagewayList(item.monitorEquipmentId);
        if (this.passagewayList.length == 0) {
            this.$alert("此监控下没有监控设备！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return
        }
        this.selectData = item;
        this.title = item.monitorEquipmentName;
        this.dialogVisible = false
        this.isShowVideoDialog = true;
        this.$nextTick(() => {
          this.$refs.videoMonitor.open();
        });
    },
    async videoChange() {
      if (this.passagewayPage > this.passagewayFlag) {
        this.passagewayPage = 1;
      }
      await this.getPassagewayList(this.selectData.monitorEquipmentId);
      this.$nextTick(() => {
        this.$refs.videoMonitor.clearData();
      });
    },
    getPassagewayList(monitorEquipmentId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/monitorEquipmentChannel/list", {
            data: {
              page: this.passagewayPage,
              pageSize: this.passagewayPageSize,
              monitorEquipmentId
            }
          })
          .then(res => {
            if (res.state == 0) {
              this.passagewayList = res.value.list;
              this.passagewayTotal = res.value.total;
              this.passagewayFlagA = this.passagewayTotal % this.passagewayPageSize;
              this.passagewayFlagB = parseInt(this.passagewayTotal / this.passagewayPageSize);
              if (this.passagewayFlagA > 0) {
                this.passagewayFlag = this.passagewayFlagB + 1;
              } else {
                this.passagewayFlag = this.passagewayFlagB;
              }
              // console.log(this.passagewayFlag);
              this.passagewayPage++;
              // this.passagewayFlag = this.passagewayTotal/this.passagewayPageSize
            } else {
              this.passagewayPage = 1;
              this.passagewayList = [];
              this.passagewayTotal = 0;
            }
            if (this.passagewayTotal > 5) {
              this.isShowChange = true;
            } else {
              this.isShowChange = false;
            }
            resolve();
          });
      });
    },
  },
  watch: {

  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.monitorDetails
  >>>.el-dialog--center
    border 1px solid #2ca6ff
    border-radius 4px
    overflow hidden
    .el-dialog__header
      background #07226f
      padding 8px 10px
      .el-dialog__title
        color #fff
        font-size 14px
    .el-dialog__body
      background-color rgba(13, 16, 62, 1)
      padding 15px 0 15px 15px
      .dialogVideo
        width 100%
        height 480px
      .monitorBox
        .monitor
          float left
          width 138px
          height 107px
          background rgba(255,255,255,0.1)
          border-radius 4px
          margin 0 15px 15px 0
          text-align center
          cursor pointer
          .img
            display inline-block
            width 36px
            height 36px
            margin 17px 0 7px
          .onimg
            background url('../img/newVideo.png') no-repeat center / 100% 100%
          .offimg
            background url('../img/offline.png') no-repeat center / 100% 100%
          .monitorName
            padding 10px
            font-size 12px
            color #fff
    .el-dialog__footer
      background-color #07226f
      padding-bottom 10px
      .dialog-footer
        .el-button
          width 88px
          height 30px
          padding 0
          color #fff
          background-color #0076F5
          border none
          margin-right 10px
</style>
